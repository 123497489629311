/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UnderPaymentViewModel {
  id?: string;
  patientId?: string;
  accountNumber?: number;
  patientName?: string | null;
  visitId?: string;
  visitNumber?: number;
  procedureCode?: string | null;
  procedureAmount?: number;
  serviceDate?: string;
  procedureBalance?: number;
  insuranceCode?: string | null;
  batchNumber?: number;
  postingDate?: string;
  message?: string | null;
}
