/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { AddPatientViewModel } from '../model/addPatientViewModel';
// @ts-ignore
import { ImaginePayUnappliedPaymentViewModelPagedModelResponse } from '../model/imaginePayUnappliedPaymentViewModelPagedModelResponse';
// @ts-ignore
import { MergePatientViewModel } from '../model/mergePatientViewModel';
// @ts-ignore
import { NotFoundResult } from '../model/notFoundResult';
// @ts-ignore
import { Operation } from '../model/operation';
// @ts-ignore
import { PagedModelRequest } from '../model/pagedModelRequest';
// @ts-ignore
import { PatientDetailsViewModel } from '../model/patientDetailsViewModel';
// @ts-ignore
import { PatientDetailsViewModelPagedModelResponse } from '../model/patientDetailsViewModelPagedModelResponse';
// @ts-ignore
import { PatientLedgerRequestModel } from '../model/patientLedgerRequestModel';
// @ts-ignore
import { PatientNumberViewModel } from '../model/patientNumberViewModel';
// @ts-ignore
import { PatientReceiptRequestModel } from '../model/patientReceiptRequestModel';
// @ts-ignore
import { PatientSearchViewModelPagedModelResponse } from '../model/patientSearchViewModelPagedModelResponse';
// @ts-ignore
import { PatientViewModel } from '../model/patientViewModel';
// @ts-ignore
import { PatientViewModelPagedModelResponse } from '../model/patientViewModelPagedModelResponse';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { VisitDetailsViewModelPagedModelResponse } from '../model/visitDetailsViewModelPagedModelResponse';
// @ts-ignore
import { ZeroBalanceRequestViewModel } from '../model/zeroBalanceRequestViewModel';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Adds a new entity. (Auth required: Accounts: Update)
   * Adds a new entity
   * @param datasetId
   * @param addPatientViewModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientAddPost(
    datasetId: string,
    addPatientViewModel?: AddPatientViewModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModel>;
  public apiV1PatientAddPost(
    datasetId: string,
    addPatientViewModel?: AddPatientViewModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModel>>;
  public apiV1PatientAddPost(
    datasetId: string,
    addPatientViewModel?: AddPatientViewModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModel>>;
  public apiV1PatientAddPost(
    datasetId: string,
    addPatientViewModel?: AddPatientViewModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientAddPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/patient/add`,
      addPatientViewModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets all entity records with detail. (Auth required: Accounts: View)
   * Gets all detailed entities with applied paging and filtering
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModelPagedModelResponse>;
  public apiV1PatientAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModelPagedModelResponse>>;
  public apiV1PatientAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModelPagedModelResponse>>;
  public apiV1PatientAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientAllDetailPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientDetailsViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/patient/all-detail`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets all entity records. (Auth required: Accounts: View)
   * Gets all entities with applied paging and filtering
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientViewModelPagedModelResponse>;
  public apiV1PatientAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientViewModelPagedModelResponse>>;
  public apiV1PatientAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientViewModelPagedModelResponse>>;
  public apiV1PatientAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientAllPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/patient/all`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific entity by id with included related details. (Auth required: Accounts: View)
   * Example id: 12345678-ABCD-ABCD-ABCD-123456789012
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModel>;
  public apiV1PatientDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModel>>;
  public apiV1PatientDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModel>>;
  public apiV1PatientDetailsIdGet(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientDetailsIdGet.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientDetailsIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<PatientDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/patient/details/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes a record. (Auth required: Accounts: Update)
   * Marks a record as deleted. (soft delete)
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientIdDelete(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1PatientIdDelete(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1PatientIdDelete(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1PatientIdDelete(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientIdDelete.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientIdDelete.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/api/v1/patient/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific entity by id. (Auth required: Accounts: View)
   * Example id: 12345678-ABCD-ABCD-ABCD-123456789012
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientIdGet(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientViewModel>;
  public apiV1PatientIdGet(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientViewModel>>;
  public apiV1PatientIdGet(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientViewModel>>;
  public apiV1PatientIdGet(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientIdGet.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<PatientViewModel>(
      `${this.configuration.basePath}/api/v1/patient/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve patient unapplied payments. (Auth required: Accounts: View)
   * Retrieve patient unapplied payments
   * @param id
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientIdUnappliedPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ImaginePayUnappliedPaymentViewModelPagedModelResponse>;
  public apiV1PatientIdUnappliedPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ImaginePayUnappliedPaymentViewModelPagedModelResponse>>;
  public apiV1PatientIdUnappliedPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ImaginePayUnappliedPaymentViewModelPagedModelResponse>>;
  public apiV1PatientIdUnappliedPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientIdUnappliedPost.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientIdUnappliedPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayUnappliedPaymentViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/patient/${encodeURIComponent(String(id))}/unapplied`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve patient visits. (Auth required: Accounts: View)
   * Retrieve patient visits
   * @param id
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientIdVisitsPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<VisitDetailsViewModelPagedModelResponse>;
  public apiV1PatientIdVisitsPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<VisitDetailsViewModelPagedModelResponse>>;
  public apiV1PatientIdVisitsPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<VisitDetailsViewModelPagedModelResponse>>;
  public apiV1PatientIdVisitsPost(
    id: string,
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientIdVisitsPost.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientIdVisitsPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<VisitDetailsViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/patient/${encodeURIComponent(String(id))}/visits`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a list of codes by id. (Auth required: System Maintenance: View)
   * Gets a list of codes by id.
   * @param datasetId
   * @param requestBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientIdsPost(
    datasetId: string,
    requestBody?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<PatientDetailsViewModel>>;
  public apiV1PatientIdsPost(
    datasetId: string,
    requestBody?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<PatientDetailsViewModel>>>;
  public apiV1PatientIdsPost(
    datasetId: string,
    requestBody?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<PatientDetailsViewModel>>>;
  public apiV1PatientIdsPost(
    datasetId: string,
    requestBody?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientIdsPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<Array<PatientDetailsViewModel>>(
      `${this.configuration.basePath}/api/v1/patient/ids`,
      requestBody,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Downloads the account ledger for a patient.
   * Downloads the account ledger for a patient.
   * @param datasetId
   * @param patientLedgerRequestModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientLedgerPdfPost(
    datasetId: string,
    patientLedgerRequestModel?: PatientLedgerRequestModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Blob>;
  public apiV1PatientLedgerPdfPost(
    datasetId: string,
    patientLedgerRequestModel?: PatientLedgerRequestModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Blob>>;
  public apiV1PatientLedgerPdfPost(
    datasetId: string,
    patientLedgerRequestModel?: PatientLedgerRequestModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Blob>>;
  public apiV1PatientLedgerPdfPost(
    datasetId: string,
    patientLedgerRequestModel?: PatientLedgerRequestModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientLedgerPdfPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post(`${this.configuration.basePath}/api/v1/patient/ledger-pdf`, patientLedgerRequestModel, {
      context: localVarHttpContext,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Merges Patient associations from one patient to another and then removes the duplicate entry
   * Updates a record using mapping.
   * @param datasetId
   * @param mergePatientViewModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientMergePatientPost(
    datasetId: string,
    mergePatientViewModel?: MergePatientViewModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1PatientMergePatientPost(
    datasetId: string,
    mergePatientViewModel?: MergePatientViewModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1PatientMergePatientPost(
    datasetId: string,
    mergePatientViewModel?: MergePatientViewModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1PatientMergePatientPost(
    datasetId: string,
    mergePatientViewModel?: MergePatientViewModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientMergePatientPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v1/patient/merge-patient`,
      mergePatientViewModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patches a record. (Auth required: Accounts: Update)
   * Updates a record using the PATCH method.
   * @param id
   * @param datasetId
   * @param operation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModel>;
  public apiV1PatientPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModel>>;
  public apiV1PatientPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModel>>;
  public apiV1PatientPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientPatchIdPatch.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientPatchIdPatch.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.patch<PatientDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/patient/patch/${encodeURIComponent(String(id))}`,
      operation,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets patient account details by account number. (Auth required: Accounts: View)
   * Gets patient account details by account number
   * @param patientnumber
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientPatientNumberPatientnumberGet(
    patientnumber: number,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModel>;
  public apiV1PatientPatientNumberPatientnumberGet(
    patientnumber: number,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModel>>;
  public apiV1PatientPatientNumberPatientnumberGet(
    patientnumber: number,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModel>>;
  public apiV1PatientPatientNumberPatientnumberGet(
    patientnumber: number,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (patientnumber === null || patientnumber === undefined) {
      throw new Error(
        'Required parameter patientnumber was null or undefined when calling apiV1PatientPatientNumberPatientnumberGet.'
      );
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1PatientPatientNumberPatientnumberGet.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<PatientDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/patient/patient-number/${encodeURIComponent(String(patientnumber))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Generate a patient account number
   * Generates a new patient account number
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientPatientNumberPost(
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientNumberViewModel>;
  public apiV1PatientPatientNumberPost(
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientNumberViewModel>>;
  public apiV1PatientPatientNumberPost(
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientNumberViewModel>>;
  public apiV1PatientPatientNumberPost(
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientPatientNumberPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientNumberViewModel>(
      `${this.configuration.basePath}/api/v1/patient/patient-number`,
      null,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Purges/deletes zero balance patient accounts and associations
   * Deletes user records.
   * @param datasetId
   * @param zeroBalanceRequestViewModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientPurgeAccountsPost(
    datasetId: string,
    zeroBalanceRequestViewModel?: ZeroBalanceRequestViewModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1PatientPurgeAccountsPost(
    datasetId: string,
    zeroBalanceRequestViewModel?: ZeroBalanceRequestViewModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1PatientPurgeAccountsPost(
    datasetId: string,
    zeroBalanceRequestViewModel?: ZeroBalanceRequestViewModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1PatientPurgeAccountsPost(
    datasetId: string,
    zeroBalanceRequestViewModel?: ZeroBalanceRequestViewModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientPurgeAccountsPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v1/patient/purge-accounts`,
      zeroBalanceRequestViewModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Downloads the receipt for a patient.
   * Downloads the receipt for a patient.
   * @param datasetId
   * @param patientReceiptRequestModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientReceiptPdfPost(
    datasetId: string,
    patientReceiptRequestModel?: PatientReceiptRequestModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Blob>;
  public apiV1PatientReceiptPdfPost(
    datasetId: string,
    patientReceiptRequestModel?: PatientReceiptRequestModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Blob>>;
  public apiV1PatientReceiptPdfPost(
    datasetId: string,
    patientReceiptRequestModel?: PatientReceiptRequestModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Blob>>;
  public apiV1PatientReceiptPdfPost(
    datasetId: string,
    patientReceiptRequestModel?: PatientReceiptRequestModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientReceiptPdfPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.configuration.basePath}/api/v1/patient/receipt-pdf`,
      patientReceiptRequestModel,
      {
        context: localVarHttpContext,
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search patient accounts. (Auth required: Accounts: View)
   * Search patient accounts and return a paged list
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientSearchPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientSearchViewModelPagedModelResponse>;
  public apiV1PatientSearchPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientSearchViewModelPagedModelResponse>>;
  public apiV1PatientSearchPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientSearchViewModelPagedModelResponse>>;
  public apiV1PatientSearchPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1PatientSearchPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientSearchViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/patient/search`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific entity by id with limited details for payment entry. (Auth required: Accounts: View)
   * Example id: 12345678-ABCD-ABCD-ABCD-123456789012
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientSelectionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientDetailsViewModel>;
  public apiV1PatientSelectionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientDetailsViewModel>>;
  public apiV1PatientSelectionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientDetailsViewModel>>;
  public apiV1PatientSelectionDetailsIdGet(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientSelectionDetailsIdGet.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1PatientSelectionDetailsIdGet.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<PatientDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/patient/selection-details/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates patient banner message (Auth required: Accounts: Update)
   * Updates the patient banner message
   * @param id
   * @param datasetId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientUpdateBannerIdPost(
    id: string,
    datasetId: string,
    body?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1PatientUpdateBannerIdPost(
    id: string,
    datasetId: string,
    body?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1PatientUpdateBannerIdPost(
    id: string,
    datasetId: string,
    body?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1PatientUpdateBannerIdPost(
    id: string,
    datasetId: string,
    body?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1PatientUpdateBannerIdPost.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1PatientUpdateBannerIdPost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v1/patient/update-banner/${encodeURIComponent(String(id))}`,
      body,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Checks if account number is valid for a new patient.
   * Checks if account number is valid for a new patient.
   * @param datasetId
   * @param accountNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1PatientValidateAccountNumberPost(
    datasetId: string,
    accountNumber?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PatientNumberViewModel>;
  public apiV1PatientValidateAccountNumberPost(
    datasetId: string,
    accountNumber?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PatientNumberViewModel>>;
  public apiV1PatientValidateAccountNumberPost(
    datasetId: string,
    accountNumber?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PatientNumberViewModel>>;
  public apiV1PatientValidateAccountNumberPost(
    datasetId: string,
    accountNumber?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1PatientValidateAccountNumberPost.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (accountNumber !== undefined && accountNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>accountNumber, 'accountNumber');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<PatientNumberViewModel>(
      `${this.configuration.basePath}/api/v1/patient/validate-account-number`,
      null,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
