import { formatPercent } from '@angular/common';
import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BodyClasses,
  ColumnSizes,
  CustomValidators,
  FieldSelectOption,
  FilterType,
  FormGroupDefinition
} from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { FinancialClassesLookupService } from 'src/app/core/services/lookup';

import { ZeroBalanceService } from './zero-balance.service';

export const ZeroBalance: Report = {
  id: 'zero-balance',
  label: 'Zero Balance Report',
  subText: '',
  searchFormClass: 'zero-balance-report',
  textCriteria: {
    'Financial Classes': '',
    'Months At 0': '',
    'Start Date': '',
    'End Date': '',
    'Start Payment Date': '',
    'End Payment Date': '',
    'Start Statement Date': '',
    'End Statement Date': '',
    'Only Patients without Visits': ''
  },
  cleanFilterParams: {
    financialClassIds: ''
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-8 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = [];
            let listText = [];
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value);
              listText.push(x.label);
            });
            ZeroBalance.textCriteria['Financial Classes'] = listText.join(',');
            ZeroBalance.cleanFilterParams['financialClassIds'] = listValue;
          }
        },
        {
          label: 'Months At Zero (> or = to)',
          name: 'monthsAtZero',
          class: 'form-span-4',
          type: 'number',
          min: 0,
          max: 999,
          selectionChanged: (event) => {
            ZeroBalance.textCriteria['Months At 0'] = event;
          },
          initial: 0,
          validators: [Validators.required, Validators.min(0), Validators.max(999)]
        },
        {
          label: 'Last Procedure Service Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            ZeroBalance.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ZeroBalance.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: 'Last Payment Posted Date Range',
          name: 'paymentDateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            ZeroBalance.textCriteria['Start Payment Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ZeroBalance.textCriteria['End Payment Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'paymentDateRangeStart',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: '',
          name: 'paymentDateRangeEnd',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: 'Last Statement Date Range',
          name: 'statementDateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            ZeroBalance.textCriteria['Start Statement Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ZeroBalance.textCriteria['End Statement Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'statementDateRangeStart',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: '',
          name: 'statementDateRangeEnd',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: 'Only Archive Accounts without Visits',
          name: 'onlyDemos',
          class: 'form-span-12 zero-balance-toggle',
          type: 'toggle',
          initial: false,
          toggleChanged: (event) => {
            ZeroBalance.textCriteria['Only Patients without Visits'] = event.checked === true ? 'Yes' : 'No'
          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe('en-US');
    return {
      service: injector.get<any>(ZeroBalanceService),
      columnDefinitions: [
        {
          id: 'AccountNumber',
          displayKey: 'AccountNumber',
          headerText: 'Account Number',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: false
        },
        {
          id: 'PatientName',
          displayKey: 'PatientName',
          headerText: 'Patient Name',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: false
        },
        {
          id: 'FinancialClass',
          displayKey: 'FinancialClass',
          headerText: 'Financial Class',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: false
        },
        {
          id: 'LastProcedureServiceDate',
          displayKey: 'LastProcedureServiceDate',
          headerText: 'Procedure Service Date',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          filterable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'LastProcedureServiceDate'
          },
          formatter: (x) => {
            return datePipe.transform(x);
          },
          sortable: false
        },
        {
          id: 'LastPaymentPostedDate',
          displayKey: 'LastPaymentPostedDate',
          headerText: 'Payment Posted Date',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          filterable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'LastPaymentPostedDate'
          },
          formatter: (x) => {
            return datePipe.transform(x);
          },
          sortable: false
        },
        {
          id: 'LastStatementDate',
          displayKey: 'LastStatementDate',
          headerText: 'Statement Date',
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'LastStatementDate'
          },
          filterable: true,
          formatter: (x) => {
            return datePipe.transform(x);
          },
          sortable: false
        },
        {
          id: 'MonthsAtZero',
          displayKey: 'MonthsAtZero',
          headerText: 'Months At Zero',
          displayOrder: 6,
          columnSize: ColumnSizes.extraNarrow,
          filterable: false,
          sortable: false
        }
      ]
    };
  }
};
