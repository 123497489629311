/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EobPaymentViewModel {
  id?: string;
  code?: string | null;
  name?: string | null;
  doWriteOffProc?: boolean;
  doWriteOffAmt?: boolean;
  adjustmentCodeId?: string | null;
  billNext?: boolean;
  patientResponsible?: boolean;
  isDenied?: boolean;
  isUnderPaid?: boolean;
  isFollowUp?: boolean;
  suppressSecondary?: boolean;
}
