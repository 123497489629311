<button mat-icon-button [matMenuTriggerFor]="menu" class="lib-overflow-menu__button">
  <mat-icon class="lib-overflow-menu__button--icon">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div *ngFor="let option of options; let i = index; trackBy: trackById">
    <button
      *ngIf="
        (!option.field ||
          data[option.field] === option.condition ||
          (option.condition === true && data[option.field])) &&
        (!option.subfield || data[option.subfield] === option.subcondition) &&
        !option.hide
      "
      (click)="optionClicked.emit(option.id)"
      id="option{{ i }}"
      mat-menu-item
    >
      <span>{{ option.label }}</span>
    </button>
  </div>
</mat-menu>
