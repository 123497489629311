import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddReminderViewModel } from 'data';
import { take } from 'rxjs/operators';
import { PatientLookupService } from 'src/app/core/services/lookup/patient-lookup.service';
import { UserLookupService } from 'src/app/core/services/lookup/user-lookup.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ReminderWrapperService } from 'src/app/core/services/service-wrappers/reminder-wrapper.service';

@Component({
  selector: 'app-reminder-form',
  templateUrl: './reminder-form.component.html',
  styleUrls: ['./reminder-form.component.scss']
})
export class ReminderFormComponent implements OnInit {
  @Input()
  clientId;

  @Output()
  cancelEvent = new EventEmitter<any>();

  formGroup = new UntypedFormGroup({
    patientId: new UntypedFormControl(''),
    assignedUserId: new UntypedFormControl('', Validators.required),
    scheduledDate: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl('', Validators.required)
  });

  saving = false;

  constructor(
    public patientLookupService: PatientLookupService,
    public userLookupService: UserLookupService,
    private reminderService: ReminderWrapperService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}
  save() {
    const model: AddReminderViewModel = {
      ...this.formGroup.value,
      patientId: this.formGroup.get('patientId').value
      //   // customerId: '',
      //   // patientId: '',
      //   // userId: ''
    };
    this.saving = true;
    this.reminderService
      .apiV1ReminderAddPost(model)
      .pipe(take(1))
      .subscribe(
        (x) => {
          this.saving = false;
          this.formGroup.reset();
          this.cancelEvent.emit(true);
        },
        (err) => this.notificationService.error('Creating Reminder')
      );
  }

  cancel() {
    this.formGroup.reset();
    this.cancelEvent.emit(false);
  }
}
